import React, { Component } from 'react'
import PropTypes from "prop-types";

class Form extends Component {
  render() {
    return (
      <form onSubmit={this.props.onSubmit}>
        <div className="flex items-center">
          <input 
            className="bg-white focus:outline-none focus:shadow-outline border border-gray-300 rounded-lg py-2 px-4 block w-full appearance-none leading-normal"
            type="text" 
            name="url" 
            aria-label="Podcast Feed URL"
            aria-required="true"
            placeholder="Podcast Feed URL"
            value={this.props.url}
            onChange={this.props.onChange} />
          <button 
            type="submit" 
            className="focus:outline-none flex-shrink-0 mx-2 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                Submit
          </button>
        </div>
      </form>
    );
  }
}

Form.propTypes = {
  url: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default Form;
