import React from "react";
import PropTypes from "prop-types";

function Scan() {
  return <div dataId={this.props.uuid}>Scan Results</div>;
}

Scan.defaultProps = {
  uuid: null,
  data: {},
};

Scan.propTypes = {
  uuid: PropTypes.string.isRequired,
  data: PropTypes.node,
};

export default Scan;
