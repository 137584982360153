import React, { Component } from "react";
import axios from "axios";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Form from "../components/validator/form";
import Loading from "../components/validator/loading";
import Scan from "../components/validator/scan";

const KEYWORDS = [`feed validator`, `validation`];

const baseURL =
  process.env.NODE_ENV === "development"
    ? "http://localhost:5000"
    : "https://app.pinwheel.fm";

const headers = { "X-Pinwheel-Client": "0.1" };

const client = axios.create({
  baseURL: baseURL,
  timeout: 1000,
  headers: headers,
});

const statuses = {
  INITIALIZED: "initialized",
  SUBMITTED: "submitted",
  ERROR: "error",
  STARTED: "started",
  COMPLETED: "completed",
};

function Warning() {
  return <p>Oh snap!</p>;
}

export default class ValidatorPage extends Component {
  state = {
    status: false,
    url: "",
    scan: {},
  };

  handleInputChange = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  };

  handleSubmit = (event) => {
    event.preventDefault();

    const { url } = this.state;

    this.setState({ status: statuses.SUBMITTED });

    let self = this;
    client
      .post("/api/scans", { feed_url: url })
      .then(function (response) {
        console.log(response.data);
        console.log(response.data.state);
        console.log(this);
        self.setState({ status: response.data.state, scan: response.data });
      })
      .catch(() => {
        this.setState({ status: statuses.ERROR });
      });
  };

  render() {
    const { status, url, scan } = this.state;

    return (
      <Layout>
        <SEO keywords={KEYWORDS} title="Podcast Feed Validator" />

        <div className="container mx-auto px-6 py-2 flex justify-between items-center">
          <section className="flex flex-col items-center md:flex-row">
            <Form
              onSubmit={this.handleSubmit}
              onChange={this.handleInputChange}
              url={url}
            />

            {(() => {
              switch (status) {
                case statuses.SUBMITTED:
                case statuses.INITIALIZED:
                  return <Loading />;
                case statuses.ERROR:
                  return <Warning />;
                case statuses.STARTED:
                case statuses.COMPLETED:
                  return <Scan uuid={scan.uuid} data={scan} />;
                default:
                  return null;
              }
            })()}
          </section>
        </div>
      </Layout>
    );
  }
}
